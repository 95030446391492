import * as React from "react"
import { Link } from "gatsby";
import Layout from '../components/layout';

import { videos } from "../videos-config";

const IndexPage = () => {
  return (
    <main class="px-3 py-4">
      <title>
        📼 VHS Transfers 📼
      </title>
      <Layout>
        <div>
          <h2 class="mt-2 mb-3">Click on the VHS 📼 to watch video</h2>
        </div>
        <div class="row">
          {videos.map((video, i) => {
            return (
              <div class="col-sm-6 col-md-6 col-lg-4 mb-5">
                <div class="card">
                  <Link key={video.title + i} to={video.url}>
                    <img src={video.image.front} class="card-img-top" alt={video.title} />
                    <img src={video.image.side} class="card-img-top" alt={video.title} />
                    <div class="card-body">
                        <h5 class="card-title">{video.title}</h5>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </Layout>
    </main>
  )
}

export default IndexPage
