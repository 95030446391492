exports.videos = [
    {
      title: 'Caroline Nicole Rodriguez'
      , url: 'caroline_nicole_rodriguez'
      , image: {
          front: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180744965.jpg'
          , side: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180756986.jpg'
      }
      , source: {
          mp4: 'https://vhstransfers.s3.amazonaws.com/caroline_nicole_rodriguez.mp4'
          , webm: 'https://vhstransfers.s3.amazonaws.com/caroline_nicole_rodriguez.webm'
      }
    }
    , {
      title: 'Compleanos Don Luis - Viaje Suegrs - Terminacon Local'
      , url: 'compleanos_don_luis'
      , image: {
          front: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180407082.jpg'
          , side: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180415248.jpg'
      }
      , source: {
          mp4: 'https://vhstransfers.s3.amazonaws.com/compleanos_don_luis_libx264_crf24.mp4'
          , webm: 'https://vhstransfers.s3.amazonaws.com/compleanos_don_luis_libx264_crf24.webm'
      }
    }
    , {
      title: 'Great Falls Jr.'
      , url: 'great_falls_jr'
      , image: {
          front: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180432682.jpg'
          , side: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180449138.jpg'
      }
      , source: {
          mp4: 'https://vhstransfers.s3.amazonaws.com/great_falls_jr.mp4'
          , webm: 'https://vhstransfers.s3.amazonaws.com/great_falls_jr.webm'
      }
    }
    , {
      title: 'Junior\'s Birthday - Obra del Tratro en el Colegio - Parada del July 4th - Viaje a Canada (Niagra Falls) 1991'
      , url: 'jr_birthday_obra_parada_niagra_falls_1991'
      , image: {
          front: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180657251.jpg'
          , side: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180702637.jpg'
      }
      , source: {
          mp4: 'https://vhstransfers.s3.amazonaws.com/JR_birthday_obra_parada_niagra_falls_1991.mp4'
          , webm: 'https://vhstransfers.s3.amazonaws.com/JR_birthday_obra_parada_niagra_falls_1991.webm'
      }
    }
    , {
      title: 'Junior\'s Bautismo - Halloween Farm - Party - Christmas Dinner 1990'
      , url: 'jrs_baulismo_halloween_party_christmas_dinner_1990'
      , image: {
          front: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180634623.jpg'
          , side: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180643249.jpg'
      }
      , source: {
          mp4: 'https://vhstransfers.s3.amazonaws.com/jrs_baulismo_halloween_party_christmas_dinner_1990.mp4'
          , webm: 'https://vhstransfers.s3.amazonaws.com/jrs_baulismo_halloween_party_christmas_dinner_1990.webm'
      }
    }
    , {
      title: 'Junior\'s Birthday - Shenandoa Trip - 1990'
      , url: 'jrs_birthday_shenandoa'
      , image: {
          front: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180814432.jpg'
          , side: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180819977.jpg'
      }
      , source: {
          mp4: 'https://vhstransfers.s3.amazonaws.com/jrs_birthday_shenandoa.mp4'
          , webm: 'https://vhstransfers.s3.amazonaws.com/jrs_birthday_shenandoa.webm'
      }
    }
    , {
      title: 'Lucy\'s New Year with Family 12-31-90'
      , url: 'lucy_new_year_with_family_12-31-90'
      , image: {
          front: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180525028.jpg'
          , side: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180531909.jpg'
      }
      , source: {
          mp4: 'https://vhstransfers.s3.amazonaws.com/lucys_new_year_with_family_12_31_90.mp4'
          , webm: 'https://vhstransfers.s3.amazonaws.com/lucys_new_year_with_family_12_31_90.webm'
      }
    }
    , {
      title: 'Ocean City 08-10-91 to 08-12-91'
      , url: 'ocean_city_08-10-91_to_08-12-91'
      , image: {
          front: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180607270.jpg'
          , side: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180616516.jpg'
      }
      , source: {
          mp4: 'https://vhstransfers.s3.amazonaws.com/ocean_city_08_10_91_to_08_12_91.mp4'
          , webm: 'https://vhstransfers.s3.amazonaws.com/ocean_city_08_10_91_to_08_12_91.webm'
      }
    }
    , {
      title: 'Regreso Mamy y mi rey - Domingo 25 Febrero - Cheery Blossom - Great Falls - 1990'
      , url: 'pregresso_cherry_great_falls_1990'
      , image: {
          front: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180716919.jpg'
          , side: 'https://vhstransfers.s3.amazonaws.com/images/compressed/PXL_20201224_180722583.jpg'
      }
      , source: {
          mp4: 'https://vhstransfers.s3.amazonaws.com/pregresso_cherry_great_falls_1990.mp4'
          , webm: 'https://vhstransfers.s3.amazonaws.com/pregresso_cherry_great_falls_1990.webm'
      }
    }
  ];